<template>
  <div>
    <appbar @drawer="open" :profile="profile" />
    <appdrawer :drawer="value" :profile="profile"></appdrawer>
    <v-sheet
      style="height: 100vh; background-color:rgba(155, 155, 155, 0.08)"
      id="scrolling-techniques-7"
      class="overflow-y-auto"
    >
      <v-main style="height: 100vh;"><router-view /></v-main>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Drawer from './Drawer';
import Header from './Header';
export default {
  name: 'Master',
  components: {
    appbar: Header,
    appdrawer: Drawer,
  },
  computed: {
    ...mapGetters({
      profile: 'auth/getUserDetails',
    }),
  },
  data() {
    return {
      value: true,
    };
  },

  methods: {
    open(value) {
      this.value = value;
    },
  },
  created() {
    if (
      localStorage.auth_token !== null ||
      localStorage.auth_token !== undefined
    ) {
      this.$store.dispatch('auth/me');
    }
  },
};
</script>
